export default {
  primary: {
    color: "background",
    borderRadius: 0,
    bg: "primary",
    "&:hover": {
      bg: "text",
    },
  },
}
