export default {
  wrapper: {
    borderBottom: "medium solid",
    borderBottomColor: "accentTwo",
    position: "relative",
    "&:before": {
      bg: "primary",
      borderRadius: 20,
      bottom: 0,
      content: "''",
      height: 20,
      left: [20, 33],
      position: "absolute",
      transform: "translateY(50%)",
      width: 20,
      zIndex: 2,
    },
  },
  year: {
    fontSize: [3, 4],
    fontWeight: "bold",
    color: "primary",
    pb: 5,
    mr: 3,
    width: ["175px", "85px"],
    position: "relative",
    textAlign: "center",
    "&:after": {
      borderRight: "2px solid",
      borderRightColor: "primary",
      content: "''",
      height: "40px",
      left: "50%",
      position: "absolute",
      display: "block",
      bottom: "20px",
      width: 0,
    },
  },
}
