export default {
  tile: {
    p: 3,
    bg: "secondary",
    color: "dark",
    fontSize: [2, 4],
    fontWeight: "bold",
    ml: 3,
    maxWidth: "calc(100% - 1rem)",
    transform: "translateY(-50%)",
    display: "inline-block",
  },
}
