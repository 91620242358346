export default {
  root: {
    fontFamily: "body",
    lineHeight: "body",
    fontWeight: "body",
  },
  h1: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "heading",
    fontSize: [5, 6, 7],
  },
  h2: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    mt: 4,
    mb: 3,
    fontWeight: "heading",
    fontSize: [4, 5, 6],
  },
  h3: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    mt: 3,
    mb: 2,
    fontWeight: "heading",
    fontSize: [3, 4, 5],
  },
  h4: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "heading",
    mt: 3,
    mb: 2,
    fontSize: [2, 3, 4],
  },
  h5: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "heading",
    mt: 2,
    mb: 1,
    fontSize: [1, 2, 3],
  },
  h6: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "heading",
    mt: 2,
    mb: 1,
    fontSize: [0, 1, 2],
  },
  p: {
    fontFamily: "body",
    fontWeight: "body",
    fontSize: [2, 3, 3],
    lineHeight: "body",
    mb: 3,
  },
  a: {
    color: "primary",
  },
  pre: {
    fontFamily: "monospace",
    overflowX: "auto",
    code: {
      color: "inherit",
    },
  },
  code: {
    fontFamily: "monospace",
    fontSize: "inherit",
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: 0,
  },
  th: {
    textAlign: "left",
    borderBottomStyle: "solid",
  },
  td: {
    textAlign: "left",
    borderBottomStyle: "solid",
  },
  img: {
    maxWidth: "100%",
  },
}
