// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academics-js": () => import("./../../../src/pages/academics.js" /* webpackChunkName: "component---src-pages-academics-js" */),
  "component---src-pages-buildings-js": () => import("./../../../src/pages/buildings.js" /* webpackChunkName: "component---src-pages-buildings-js" */),
  "component---src-pages-climate-js": () => import("./../../../src/pages/climate.js" /* webpackChunkName: "component---src-pages-climate-js" */),
  "component---src-pages-food-js": () => import("./../../../src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-numbers-js": () => import("./../../../src/pages/numbers.js" /* webpackChunkName: "component---src-pages-numbers-js" */),
  "component---src-pages-participation-js": () => import("./../../../src/pages/participation.js" /* webpackChunkName: "component---src-pages-participation-js" */),
  "component---src-pages-transportation-js": () => import("./../../../src/pages/transportation.js" /* webpackChunkName: "component---src-pages-transportation-js" */),
  "component---src-pages-waste-js": () => import("./../../../src/pages/waste.js" /* webpackChunkName: "component---src-pages-waste-js" */)
}

