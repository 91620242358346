import buttons from "./buttons"
import styles from "./styles"
import text from "./text"
import timeline from "./timeline"

export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "'Oxygen', sans-serif",
    heading: "Verdana, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#4E4F45",
    background: "#fff",
    dark: "#1a1e25",
    muted: "#f5f5f5",
    primary: "#af1232",
    darkPrimary: "#760a30",
    secondary: "#b2bb1e",
    accentOne: "#002d6a",
    accentTwo: "#f17c14",
  },
  breakpoints: ["40em", "56em", "64em"],
  radii: 0,
  sizes: {
    container: 1300,
  },
  styles,
  buttons,
  text,
  timeline,
}
